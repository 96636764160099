export default function Feature({ icon, title, body }) {
  return (
    <div>
      <div className="flex gap-3">
        {icon}
        <h3 className="text-xl font-bold">{title}</h3>
      </div>
      <p className="text-gray-500">{body}</p>
    </div>
  );
}
