import kookminBankLogo from "./images/kookmin-bank.png";
import kookminBankLogoWebP from "./images/kookmin-bank.webp";
import logoAmber from "./images/logo-amber.png";
import logoAmberWebP from "./images/logo-amber.webp";
import { MapPinIcon, PhoneIcon } from "@heroicons/react/20/solid";
import {
  ArrowRightCircleIcon,
  BuildingOfficeIcon,
  CheckBadgeIcon,
  ChevronDownIcon,
  CreditCardIcon,
  DocumentCheckIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";

// Core modules imports are same as usual
import { Navigation, Pagination, Autoplay } from "swiper";
// Direct React component imports
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import {
  binso1,
  garden1,
  lobby1,
  lobby2,
  lobby3,
  lobby4,
  room1,
  room2,
  room3,
  room4,
  room5,
  room6,
} from "./images/gallery";

import NaverMap from "./components/NaverMap/NaverMap";
import Feature from "./components/Feature";
import IconBullet from "./components/IconBullet/IconBullet";
import RoomSpecs from "./components/RoomSpecs/RoomSpecs";

function App() {
  window.addEventListener("hashchange", function () {
    window.scrollTo(window.scrollX, window.scrollY - 20);
  });

  return (
    <div className="break-keep font-NotoSansKR">
      {/* <MenuBar /> */}

      <div
        id="hero"
        className="relative h-[540px] bg-[url('./images/hero-1.jpg')] bg-cover bg-top p-3"
      >
        <div className="flex flex-col gap-4 md:flex-row md:justify-between">
          <picture className="mt-5 ml-3 w-52">
            <source srcSet={logoAmberWebP} type="image/webp" />
            <source srcSet={logoAmber} type="image/png" />
            <img src={logoAmber} />
          </picture>
          <div className="mx-auto my-auto flex w-fit gap-4 text-lg font-medium text-amber-100 md:mr-3">
            <a href="#section-services">서비스</a>
            <a href="#section-photos">사진</a>
            <a href="#section-facilities">빈소</a>
            <a href="#section-process">상담절차</a>
            {/* <a href="#section-map">오시는길</a> */}
          </div>
        </div>
        <div className="absolute left-0 right-0 bottom-5 py-5">
          <div className="flex flex-col gap-1">
            <h1>
              <span className="block text-center font-NotoSerifKR text-[28px] font-medium text-amber-50">
                마지막 가시는 길,
              </span>
              <span className="block text-center font-NotoSerifKR text-[28px] font-medium text-amber-50">
                <span className="font-bold text-amber-50">
                  남양주한양병원 장례식장
                </span>
                이
              </span>
              <span className="block text-center font-NotoSerifKR text-[28px] font-medium text-amber-50">
                함께하겠습니다.
              </span>
            </h1>
          </div>
          <div className="m-auto my-7 w-10 border-t-2 border-amber-400"></div>
          <div className="mx-5 flex flex-col gap-4">
            <p className="text-md text-center font-bold text-amber-100">
              무엇을 도와드릴까요?
            </p>
            <div className="mx-auto flex">
              <a
                className="flex w-fit flex-col rounded-l border-r bg-amber-600 px-6 py-2 text-xl font-bold text-amber-50 shadow-md"
                href="https://map.naver.com/v5/entry/place/21634466?placePath=%2Fhome&c=14159795.3193695,4534694.8127676,15,0,0,0,dh"
              >
                <div className="mx-auto flex gap-2">
                  <MapPinIcon className="w-4" />
                  <p>길 찾기</p>
                </div>
                <p className="text-center text-xs text-amber-100">
                  4호선 오남역 부근
                </p>
              </a>
              <a
                className="flex h-16 w-fit flex-col rounded-r bg-amber-600 px-4 py-2 text-xl font-bold text-amber-50 shadow-md hover:bg-amber-700"
                href="tel:031-529-4440"
              >
                <div className="mx-auto flex gap-2">
                  <PhoneIcon className="w-4" />
                  <p>장례상담 연결</p>
                </div>
                <p className="text-center text-xs text-amber-100">
                  031-529-4440
                </p>
              </a>
            </div>
            <p className="text-center text-sm">
              365일 24시간 전문 장례지도사가 상주하고 있습니다.
            </p>
          </div>
        </div>
      </div>

      <div id="section-services" className="mt-2 flex flex-col gap-5 py-8">
        <div className="mx-6 max-w-lg md:mx-auto">
          <h2 className="mb-4 flex flex-col text-left text-3xl font-bold text-amber-600">
            남양주한양병원 장례식장 서비스 소개
          </h2>
          <div className="mb-4 w-8 border-b-2 border-amber-600"></div>
          <div className="flex flex-col gap-8 text-slate-900">
            <Feature
              icon={<BuildingOfficeIcon className="mb-1 w-7 text-gray-500" />}
              title={"최신 시설 리모델링 완료"}
              body={
                "최근 리모델링을 완료한 최신 시설을 이용하실 수 있습니다."
              }
            />
            <Feature
              icon={<CreditCardIcon className="mb-1 w-7 text-gray-500" />}
              title={"주차장 무료 이용"}
              body={
                "상주님과 조문객의 편의를 위해 대형 지상 및 지하 주차장을 무료로 운영하고 있습니다."
              }
            />
            <Feature
              icon={<DocumentCheckIcon className="mb-1 w-7 text-gray-500" />}
              title={"사체 검안서 발행 대행"}
              body={
                "자택, 요양원 등 병원 외의 장소에서 운명시 사체 검안서의 발행을 도와드립니다."
              }
            />
            <Feature
              icon={<ArrowRightCircleIcon className="mb-1 w-7 text-gray-500" />}
              title={"관내 무료 운구 및 이송"}
              body={
                "전화 상담 후 365일 24시간 상주하는 전문 장례지도사가 운구 및 이송을 도와드립니다."
              }
            />
            <Feature
              icon={<SparklesIcon className="mb-1 w-7 text-gray-500" />}
              title={"정기적 전문업체 소독"}
              body={
                "정기적으로 병원과 동시에 인증 받은 전문 업체의 소독을 실시하고 있습니다."
              }
            />
          </div>
        </div>
      </div>
      <div id="section-photos" className="mt-2 flex flex-col py-10">
        <div className="mx-6 max-w-lg md:mx-auto">
          <h2 className="mb-4 max-w-lg text-left text-3xl font-bold text-amber-600 md:mx-auto">
            시설 사진
          </h2>
          <div className="mb-4 w-8 border-b-2 border-amber-600"></div>
        </div>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="w-full max-w-md"
        >
          <SwiperSlide>
            <picture className="object-contain">
              {/* <source srcSet={kookminBankLogoWebP} type="image/webp" /> */}
              <source srcSet={lobby1} type="image/jpeg" />
              <img src={lobby1} />
            </picture>
          </SwiperSlide>
          <SwiperSlide>
            <picture className="object-contain">
              {/* <source srcSet={kookminBankLogoWebP} type="image/webp" /> */}
              <source srcSet={binso1} type="image/jpeg" />
              <img src={binso1} />
            </picture>
          </SwiperSlide>
          <SwiperSlide>
            <picture className="object-contain">
              {/* <source srcSet={kookminBankLogoWebP} type="image/webp" /> */}
              <source srcSet={lobby2} type="image/jpeg" />
              <img src={lobby2} />
            </picture>
          </SwiperSlide>
          <SwiperSlide>
            <picture className="object-contain">
              {/* <source srcSet={kookminBankLogoWebP} type="image/webp" /> */}
              <source srcSet={lobby3} type="image/jpeg" />
              <img src={lobby3} />
            </picture>
          </SwiperSlide>
          <SwiperSlide>
            <picture className="object-contain">
              {/* <source srcSet={kookminBankLogoWebP} type="image/webp" /> */}
              <source srcSet={lobby4} type="image/jpeg" />
              <img src={lobby4} />
            </picture>
          </SwiperSlide>
          <SwiperSlide>
            <picture className="object-contain">
              {/* <source srcSet={kookminBankLogoWebP} type="image/webp" /> */}
              <source srcSet={room1} type="image/jpeg" />
              <img src={room1} />
            </picture>
          </SwiperSlide>
          <SwiperSlide>
            <picture className="object-contain">
              {/* <source srcSet={kookminBankLogoWebP} type="image/webp" /> */}
              <source srcSet={room2} type="image/jpeg" />
              <img src={room2} />
            </picture>
          </SwiperSlide>
          <SwiperSlide>
            <picture className="object-contain">
              {/* <source srcSet={kookminBankLogoWebP} type="image/webp" /> */}
              <source srcSet={room3} type="image/jpeg" />
              <img src={room3} />
            </picture>
          </SwiperSlide>
          <SwiperSlide>
            <picture className="object-contain">
              {/* <source srcSet={kookminBankLogoWebP} type="image/webp" /> */}
              <source srcSet={room4} type="image/jpeg" />
              <img src={room4} />
            </picture>
          </SwiperSlide>
          <SwiperSlide>
            <picture className="object-contain">
              {/* <source srcSet={kookminBankLogoWebP} type="image/webp" /> */}
              <source srcSet={room5} type="image/jpeg" />
              <img src={room5} />
            </picture>
          </SwiperSlide>
          <SwiperSlide>
            <picture className="object-contain">
              {/* <source srcSet={kookminBankLogoWebP} type="image/webp" /> */}
              <source srcSet={room6} type="image/jpeg" />
              <img src={room6} />
            </picture>
          </SwiperSlide>
          <SwiperSlide>
            <picture className="object-contain">
              {/* <source srcSet={kookminBankLogoWebP} type="image/webp" /> */}
              <source srcSet={garden1} type="image/jpeg" />
              <img src={garden1} />
            </picture>
          </SwiperSlide>
        </Swiper>
      </div>

      <div id="section-facilities" className="flex flex-col gap-5 py-10">
        <div className="mx-6 mb-6 max-w-lg md:mx-auto">
          <h2 className="mb-4 text-left text-3xl font-bold text-amber-600">
            빈소 안내
          </h2>
          <div className="mb-4 w-8 border-b-2 border-amber-600"></div>
          <div className="mx-auto flex max-w-sm flex-col gap-6">
            <RoomSpecs
              name={"VIP실"}
              size={"150형"}
              facilities={"분향실 · 접객실 · 휴게실 · 화장실"}
              occupancy={"150명 동시 입실 가능"}
            />
            <RoomSpecs
              name={"특실"}
              size={"90형"}
              facilities={"분향실 · 접객실 · 휴게실 · 화장실"}
              occupancy={"80명 동시 입실 가능"}
            />
            <RoomSpecs
              name={"1호실 · 2호실 · 3호실"}
              size={"70형"}
              facilities={"분향실 · 접객실 · 휴게실 · 화장실"}
              occupancy={"72명 동시 입실 가능"}
            />
            <div className="grid grid-cols-1 space-y-2 divide-y rounded-md border border-gray-200">
              <div className="my-3">
                <h3 className="mb-1 text-center text-lg font-medium text-amber-600">
                  로비 공통 시설
                </h3>
                <div className="mx-auto flex w-auto justify-center gap-2">
                  <picture className="w-7 object-contain">
                    <source srcSet={kookminBankLogoWebP} type="image/webp" />
                    <source srcSet={kookminBankLogo} type="image/png" />
                    <img src={kookminBankLogo} />
                  </picture>
                  <p className="text-center">KB 국민은행 ATM기</p>
                </div>
              </div>
            </div>
          </div>
          <div className="my-5 py-1 px-4 text-center text-gray-500">
            <p>
              저희 장례식장은 조문객의 편의를 위해 타 장례식장의 동급 빈소보다{" "}
              <strong className="font-bold">비교적 넓은 면적의 빈소</strong>를
              운영하고 있습니다.
            </p>
          </div>
        </div>
      </div>
      <div id="section-process" className="px-6 py-6">
        <div className="mx-auto  mb-8 max-w-lg">
          <div className="mb-5 flex flex-col">
            <h2 className="mb-4 text-left font-NotoSansKR text-3xl font-bold text-amber-600">
              장례 상담 절차 안내
            </h2>
            <div className="mb-4 w-8 border-b-2 border-amber-600"></div>
            <p>
              남양주한양병원 장례식장의 무료 장례 상담 절차는 아래와 같이
              이루어집니다.
            </p>
          </div>
          <div>
            <h3 className="mb-2 text-center text-lg font-bold">1. 전화 상담</h3>
            <a
              href="tel:031-529-4440"
              className="mx-auto flex w-52 flex-col rounded bg-amber-600 px-4 py-2 text-xl font-bold text-amber-50 shadow-lg"
            >
              <div className="mx-auto flex gap-2">
                <PhoneIcon className="w-4" />
                <p>연결하기</p>
              </div>
              <p className="text-center text-xs text-amber-100">031-529-4440</p>
            </a>
          </div>
          <ChevronDownIcon className="mx-auto my-4 w-8" />
          <h3 className="mb-2 text-center text-lg font-bold">
            2. 원스탑 장례 서비스
          </h3>
          <div className="mx-auto flex w-52 flex-col gap-2">
            <IconBullet
              icon={<CheckBadgeIcon className="w-6" />}
              text={"화장 예약 등 행정 업무"}
            />
            <IconBullet
              icon={<CheckBadgeIcon className="w-6" />}
              text={"빈소 · 제단 선택"}
            />
            <IconBullet
              icon={<CheckBadgeIcon className="w-6" />}
              text={"상복 · 도우미 선택"}
            />
            <IconBullet
              icon={<CheckBadgeIcon className="w-6" />}
              text={"운구 차량 배차"}
            />
            <IconBullet
              icon={<CheckBadgeIcon className="w-6" />}
              text={"영정 사진 제작"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
