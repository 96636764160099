export default function RoomSpecs({ name, size, facilities, occupancy }) {
  return (
    <div className="grid grid-cols-1 space-y-2 divide-y rounded-md bg-slate-100">
      <div className="mt-3 mb-1">
        <h3 className="text-center text-lg font-medium text-amber-600 mb-1">{name}</h3>
        <h4 className=" text-center text-2xl">{size}</h4>
      </div>
      <div className="text-center">
        <p className="mt-2">{facilities}</p>
      </div>
      <div className="text-center">
        <p className="mt-2">{occupancy}</p>
      </div>
      <div className="text-center"></div>
    </div>
  );
}
